import { WorkShiftType } from "@dwo/shared/dist/models/workShiftModel";
import {
  Box,
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import { Error, ExpandMore } from "@material-ui/icons";
import { TooltipCustom } from "components/TooltipCustom";
import {
  selectIsTimeOffChecked,
  selectValues,
  setValues,
} from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import { useFormik } from "formik";
import { noop } from "lodash/fp";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface CrewOverviewTableSelectTimeOffProps {
  id: string;
  idRow: number;
}

interface StyleProps {
  backgroundColor?: string;
  borderRadius?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: {
      backgroundColor: ({ backgroundColor }: StyleProps) =>
        backgroundColor || theme.palette.grey[300],
      borderRadius: ({ borderRadius }: StyleProps) => borderRadius || "2px",
      height: "40px",
      width: "105px",
      padding: 0,
      "& .MuiSelect-select:focus": {
        backgroundColor: theme.palette.grey[300],
      },
      "& .MuiSelect-icon": {
        right: 0,
        top: "calc(50% - 16px)",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.dark,
        fontSize: "2rem",
      },
      "& .MuiFilledInput-input": {
        padding: "10px 4px",
      },
    },
    dropdownMenu: {
      backgroundColor: "#F0EFEF",
      borderRadius: "2px",
      boxShadow: "1px 1px 8px #0000001A",
      color: "#838385",
      display: "flex",
      flexDirection: "column",
      marginTop: "8px",
    },
    root: {
      color: theme.palette.grey[600],
    },
    errorIcon: {
      position: "absolute",
      right: "-28px",
      bottom: "6px",
    },
  }),
);

export function CrewOverviewTableSelectTimeOff({
  id,
  idRow,
}: CrewOverviewTableSelectTimeOffProps) {
  const dispatch = useDispatch();
  const values = useSelector(selectValues);
  const isTimeOffChecked = useSelector(selectIsTimeOffChecked);
  const [typeValue, setTypeValue] = useState<string>(WorkShiftType.HOLIDAY);
  const [typeValueError, setTypeValueError] = useState<boolean | undefined>(
    undefined,
  );
  const [isRowActive, setIsRowActive] = useState(false);
  const classes = useStyles({
    backgroundColor: "#F0EFEF",
    borderRadius: "0px",
  });
  const formik = useFormik({
    initialValues: {
      type: WorkShiftType.HOLIDAY,
    },
    onSubmit: noop,
  });

  useEffect(() => {
    setIsRowActive(
      Boolean(values.find((value) => value.idRow === Number(idRow))),
    );
  }, [values, idRow]);

  useEffect(() => {
    if (formik.isValid) {
      dispatch(
        setValues({
          id,
          idRow: Number(idRow),
          inputValue: typeValue,
          inputError: typeValueError,
        }),
      );
    }
  }, [dispatch, typeValue, typeValueError, id, idRow, formik.isValid]);

  useEffect(() => {
    if (!isTimeOffChecked) {
      setTypeValue(WorkShiftType.HOLIDAY);
      setTypeValueError(undefined);
    }
  }, [isTimeOffChecked]);

  const handleChangeTypeSelect = (value: string, hasErrors: boolean) => {
    setTypeValue(value);
    setTypeValueError(hasErrors);
  };

  useEffect(() => {
    handleChangeTypeSelect(formik.values.type, !formik.isValid);
  }, [formik.values.type, formik.isValid]);

  return (
    <Box flex={1} maxWidth="85px">
      {isRowActive && Object.is(typeValue, WorkShiftType.NO_SHOW) && (
        <Box position="relative" top="40px" right="-18px">
          <TooltipCustom
            title={
              <Typography variant="subtitle2">
                "No Show" hours are not added to the total hours
              </Typography>
            }
            placement="right"
          >
            <Error className={classes.errorIcon} color="primary" />
          </TooltipCustom>
        </Box>
      )}
      <FormControl fullWidth required variant="filled">
        <Select
          id="type"
          classes={{ root: classes.root }}
          className={classes.dropdown}
          IconComponent={ExpandMore}
          labelId="type"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: undefined,
            PopoverClasses: {
              paper: classes.dropdownMenu,
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          {...formik.getFieldProps("type")}
        >
          <MenuItem value={WorkShiftType.NORMAL}>Current</MenuItem>
          <MenuItem value={WorkShiftType.HOLIDAY}>Holiday</MenuItem>
          <MenuItem value={WorkShiftType.NO_SHOW}>No Show</MenuItem>
          <MenuItem value={WorkShiftType.STAND_BY}>Stand By</MenuItem>
          <MenuItem value={WorkShiftType.CALLOUT}>Callout</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
