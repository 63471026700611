import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Paper,
  Grid,
  AppBar,
  Tabs,
  Tab,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { Layout } from "components/layout/Layout";
import React, { useEffect, useRef, useState } from "react";
import {
  clearedJobOvercostQuery,
  clearedJobQuery,
  getJobStatusOptions,
  JobTabs,
} from "utils/jobUtils";
import SearchIcon from "@material-ui/icons/Search";
import { DropdownOption } from "components/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import {
  selectRegions,
  selectLoading as selectRegionLoading,
  getAllRegions,
} from "features/region/regionSlice";
import { parseRegions } from "utils/homeUtils";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { SortHeaders } from "components/JobManagment/SortHeaders";
import { DropdownPagination } from "components/DropdownPagination";
import { CustomPagination as Pagination } from "components/Pagination";
import {
  selectLimit,
  selectCount,
  getAllJobs,
  selectJobs,
  selectIsLoading,
  createJob,
  selectCurrentJob,
  selectJobTabSelected,
  setTabSelected,
  selectJobFilters,
  selectJobQuery,
  setJobFilters,
  setJobQuery,
  selectJobOvercostFilters,
  selectJobOvercostQuery,
  setJobOvercostFilters,
  setJobOvercostQuery,
} from "features/jobs/jobsSlice";
import { rowSelectOptions } from "utils/tableUtils";
import { useHistory } from "react-router";
import { ROOT_JOB_MANAGEMENT } from "routes/Roots";
import { JobCard } from "components/JobManagment/JobCard";
import { LoadingSpinner } from "components/LoadingSpinner";
import { CreateEditJobModal } from "components/jobForm/CreateEditJobModal";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { DEFAULT_LIMIT } from "utils/sharedUtils";
import { cloneDeep } from "lodash";
import { DropdownMultiple } from "components/DropdownMultiple";
import { selectCurrentUser } from "features/logIn/sessionSlice";

const statusFilterOptions: DropdownOption[] = getJobStatusOptions();

const tabStyles = makeStyles((theme: Theme) =>
  createStyles({
    shadowLeft: {
      backgroundImage:
        "linear-gradient(270deg, rgba(131,131,133,1) 90%, rgba(31,31,31,1) 100%, rgba(0,0,0,1) 100%)",
      bottom: "8px",
      color: "white",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
    },
    shadowRight: {
      backgroundImage:
        "linear-gradient(90deg, rgba(131,131,133,1) 90%, rgba(31,31,31,1) 100%, rgba(0,0,0,1) 100%)",
      bottom: "8px",
      color: "white",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
    },
    activeTab: {
      backgroundImage:
        "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%)",
      bottom: "8px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      color: theme.palette.primary.dark,
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      outline: "none",
      textTransform: "capitalize",
    },
    tabsContainer: {
      backgroundColor: "transparent",
      boxShadow: "none",
      marginLeft: "24px",
      width: "fit-content",
      height: "40px",
      [theme.breakpoints.down("xs")]: {
        width: "271px",
      },
      zIndex: 1,
    },
    indicator: {
      display: "none",
      outline: "none",
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    filtersContainer: {
      backgroundColor: "white",
      borderRadius: "0px",
      margin: "0 24px 24px 24px",
      padding: "24px 24px 16px",
      position: "relative",
      zIndex: 2,
    },
    jobsContainer: {
      margin: "0 24px 24px 24px",
    },
    rowSelectLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    jobButton: {
      width: "100%",
    },
    clearFiltersButton: {
      backgroundColor: "#2D72E0",
      padding: "0",
      width: "100%",
    },
    search: {
      "& >.MuiInputBase-root": {
        fontSize: 15,
      },
    },
  }),
);

const DEBOUNCE_TIME_MS = 1000;

export function JobManagementScreen() {
  const loggedInUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const isFirstRun = useRef<boolean>(true);
  const [activeTab, setActiveTab] = useState(JobTabs.ALL);
  const [searchValue, setSearchValue] = useState("");
  const [region, setRegion] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [query, setQuery] = useState<ServiceOptions>({});
  const [canSearch, setCanSearch] = useState(false);
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<string>(
    rowSelectOptions[0].value,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isJobModalOpen, setIsJobModalOpen] = useState<boolean>(false);
  const classes = useStyles();
  const tabClasses = tabStyles();
  const isLoadingRegions = useSelector(selectRegionLoading);
  const isLoadingJobs = useSelector(selectIsLoading);
  const currentJob = useSelector(selectCurrentJob);
  const currentRegions = useSelector(selectRegions);
  const currentLimit = useSelector(selectLimit) || DEFAULT_LIMIT;
  const currentCount = useSelector(selectCount);
  const jobsList = useSelector(selectJobs);
  const tabSelected = useSelector(selectJobTabSelected);
  const filters = useSelector(selectJobFilters);
  const storedQuery = useSelector(selectJobQuery);
  const filtersOvercost = useSelector(selectJobOvercostFilters);
  const storedOvercostQuery = useSelector(selectJobOvercostQuery);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isLoadingJobs || isLoadingRegions) {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
  }, [isLoadingJobs, isLoadingRegions]);

  useEffect(() => {
    dispatch(getAllRegions());
  }, [dispatch]);

  useEffect(() => {
    if (isFirstRender.current) {
      setActiveTab(tabSelected);
      isFirstRender.current = false;
      setSearchValue(
        tabSelected === JobTabs.ALL
          ? filters.searchValue
          : filtersOvercost.searchValue,
      );
      setRegion(
        tabSelected === JobTabs.ALL ? filters.region : filtersOvercost.region,
      );
      setStatus(
        tabSelected === JobTabs.ALL ? filters.status : filtersOvercost.status,
      );
      setSortingOptions(
        tabSelected === JobTabs.ALL
          ? filters.sortingOptions
          : filtersOvercost.sortingOptions,
      );
      setPage(
        tabSelected === JobTabs.ALL ? filters.page : filtersOvercost.page,
      );
      setRowsPerPage(
        tabSelected === JobTabs.ALL
          ? filters.rowsPerPage
          : filtersOvercost.rowsPerPage,
      );
      setQuery(tabSelected === JobTabs.ALL ? storedQuery : storedOvercostQuery);
      return;
    }
    dispatch(getAllJobs(query));
    dispatch(
      tabSelected === JobTabs.ALL
        ? setJobFilters({
            searchValue,
            region,
            status,
            sortingOptions,
            page,
            rowsPerPage,
          })
        : setJobOvercostFilters({
            searchValue,
            region,
            status,
            sortingOptions,
            page,
            rowsPerPage,
          }),
    );
    dispatch(
      tabSelected === JobTabs.ALL
        ? setJobQuery(query)
        : setJobOvercostQuery(query),
    );
    // eslint-disable-next-line
  }, [dispatch, query, tabSelected]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    history.push(`${ROOT_JOB_MANAGEMENT}/${currentJob?.id as number}`);
  }, [currentJob, history]);

  useEffect(() => {
    if (!canSearch) return;
    const queryHandler = setTimeout(() => {
      setQuery((query) => ({
        ...query,
        where: {
          ...query.where,
          $and: {
            searchData: { $iLike: `%${searchValue.split(" ").join("%")}%` },
          },
        },
        offset: 0,
      }));
      setPage(1);
    }, DEBOUNCE_TIME_MS);

    return () => {
      clearTimeout(queryHandler);
    };
  }, [searchValue, canSearch]);

  const getA11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabPanel-${index}`,
    };
  };

  const getGradients = (index: number) => {
    if (index === 0) {
      return tabClasses.shadowLeft;
    }
    return tabClasses.shadowRight;
  };

  const getTotalPages = () => {
    const pages = Math.ceil(currentCount / currentLimit);

    if (pages >= 1) {
      return pages;
    }

    return 1;
  };

  const handleChangePage = (newPage: number) => {
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = (newPage - 1) * currentLimit;
    setPage(newPage);
    setQuery(updatedQuery);
  };

  const handleChangeTabs = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    isFirstRender.current = true;
    dispatch(setTabSelected(newValue));
  };

  const handleChageSearch = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    setCanSearch(true);
  };

  const handleClickCancel = () => setIsJobModalOpen(false);

  const handleClickNewJob = () => setIsJobModalOpen(true);

  const handleClickSave = (newJob: JobModel) => {
    dispatch(createJob(newJob));
    setIsJobModalOpen(false);
  };

  const handleSelectRegion = (selectedRegions: string[]) => {
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = 0;

    updatedQuery.where = {
      ...query.where,
      regionId:
        selectedRegions.length > 0
          ? selectedRegions.map((selectedRegion) =>
              parseInt(selectedRegion, 10),
            )
          : undefined,
    };

    setPage(1);
    setRegion(selectedRegions);
    setQuery(updatedQuery);
  };

  const handleSelectStatus = (selectedStatus: string[]) => {
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = 0;

    updatedQuery.where = {
      ...query.where,
      status: selectedStatus.length > 0 ? selectedStatus : undefined,
    };

    setPage(1);
    setStatus(selectedStatus);
    setQuery(updatedQuery);
  };

  const allRegions: DropdownOption[] = parseRegions(currentRegions);

  const handleClickSorting = (sortingValues: string[][]) => {
    const updatedQuery = cloneDeep(query);
    setSortingOptions(sortingValues);
    updatedQuery.order = sortingValues.length > 0 ? sortingValues : undefined;
    setQuery(updatedQuery);
  };

  const handleSelectRowsPerPage = (selectedValue: string) => {
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = 0;
    updatedQuery.limit = Number(selectedValue);

    setPage(1);
    setRowsPerPage(selectedValue);
    setQuery(updatedQuery);
  };

  const handleClickClearFilters = () => {
    setSearchValue("");
    setPage(1);
    setRegion([]);
    setStatus([]);
    setSortingOptions([]);
    setRowsPerPage(rowSelectOptions[0].value);
    setQuery(
      tabSelected === JobTabs.ALL ? clearedJobQuery : clearedJobOvercostQuery,
    );
  };

  return (
    <Layout>
      <Box padding="32px 24px 24px 24px">
        <Typography className={classes.header} component="h1">
          Job Management
        </Typography>
      </Box>
      <Grid alignItems="center" container spacing={0}>
        <Grid item xs={4}>
          <Box height="32px">
            <AppBar className={tabClasses.tabsContainer} position="static">
              <Tabs
                classes={{ indicator: tabClasses.indicator }}
                value={activeTab}
                onChange={handleChangeTabs}
              >
                <Tab
                  classes={{ selected: tabClasses.activeTab }}
                  className={getGradients(activeTab)}
                  label="All"
                  {...getA11yProps(0)}
                />

                <Tab
                  classes={{ selected: tabClasses.activeTab }}
                  className={getGradients(activeTab)}
                  label="Over Cost"
                  {...getA11yProps(1)}
                />
              </Tabs>
            </AppBar>
          </Box>
        </Grid>
      </Grid>
      <Paper className={classes.filtersContainer}>
        <Grid container spacing={3}>
          <Grid item xl={6} sm={4} xs={12}>
            <TextField
              className={classes.search}
              placeholder="by Job ID, Address, Foreman, WO#, Client, Supervisor"
              margin="normal"
              variant="outlined"
              value={searchValue}
              InputProps={{
                type: "search",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleChageSearch}
            />
          </Grid>
          <Grid item xl={2} sm={3} xs={6}>
            <DropdownMultiple
              id="regionDropdown"
              label="Regions"
              options={allRegions}
              selectedValue={region}
              onSelectOption={handleSelectRegion}
            />
          </Grid>
          <Grid item xl={2} sm={3} xs={6}>
            <DropdownMultiple
              id="statusDropdown"
              label="Status"
              options={statusFilterOptions}
              selectedValue={status}
              onSelectOption={handleSelectStatus}
            />
          </Grid>
          <Grid item xl={1} sm={2} xs={12}>
            <Button
              variant="contained"
              className={classes.clearFiltersButton}
              onClick={handleClickClearFilters}
            >
              Clear Filters
            </Button>
          </Grid>
          <Grid item xl={1} sm={2} xs={12}>
            {loggedInUser?.employee.role === "admin" && (
              <Button
                variant="contained"
                className={classes.jobButton}
                onClick={handleClickNewJob}
              >
                New Job
              </Button>
            )}
          </Grid>
        </Grid>

        <Box marginTop="32px">
          <SortHeaders
            sortingOptions={sortingOptions}
            onClickSort={handleClickSorting}
          />
        </Box>
      </Paper>
      <Box className={classes.jobsContainer}>
        {isLoading && <LoadingSpinner />}
        {!isLoading &&
          jobsList.map((job) => (
            <Box marginBottom="16px" key={job.jobId}>
              <JobCard jobData={job} />
            </Box>
          ))}
        {!isLoading && currentCount === 0 && (
          <Box display="flex" justifyContent="center">
            <Typography>No jobs found</Typography>
          </Box>
        )}
      </Box>
      <Box padding="24px">
        <Grid alignItems="center" container spacing={4}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <Pagination
                id="usersManagement-pagination"
                isDisabled={isLoading || currentCount === 0}
                page={page}
                totalPages={getTotalPages()}
                onChangePage={handleChangePage}
              />
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box marginRight="8px">
                <Typography
                  variant="caption"
                  className={classes.rowSelectLabel}
                >
                  Rows per page:
                </Typography>
              </Box>
              <DropdownPagination
                id="rowsDropdown"
                isDisabled={isLoading || currentCount === 0}
                options={rowSelectOptions}
                selectedValue={rowsPerPage}
                onSelectOption={handleSelectRowsPerPage}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <CreateEditJobModal
        isOpen={isJobModalOpen}
        onClickClose={handleClickCancel}
        onSubmit={handleClickSave}
      />
    </Layout>
  );
}
